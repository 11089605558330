import { Middleware } from "redux";

import { showedModal } from "@app/tracking/modals-tracking";

import { getTracker } from "./utils";
import { closeModal, openModal } from "../state/modals";
import { logUserIn } from "../state/user";

type HandledAction = typeof openModal | typeof closeModal | typeof logUserIn;

export const modalsMiddleware: Middleware =
  store => next => (action: HandledAction) => {
    const { dispatch } = store;
    if (logUserIn.match(action)) {
      dispatch(closeModal());
    }
    if (openModal.match(action)) {
      getTracker()?.track(showedModal(action.payload.type));
    }
    return next(action);
  };
