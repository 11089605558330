import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";

import {
  clearPlaceholderModal,
  showPlaceholderModalEarly
} from "@app/modules/search/helpers/mobile-placeholder-modal";
import { ModalType } from "@app/types/modal";

const openModalReducer: CaseReducer<ModalType[], PayloadAction<ModalType>> = (
  state: ModalType[],
  { payload: modal }
) => {
  if (modal.display_loading_placeholder) {
    showPlaceholderModalEarly();
  }
  if (modal.close_existing) {
    state = [modal];
  }
  if (!state.length) {
    state.push(modal);
  }
  return state;
};

const closeModalReducer: CaseReducer<
  ModalType[],
  PayloadAction<ModalType["type"] | "all" | undefined>
> = (state: ModalType[], { payload: modal_type }) => {
  clearPlaceholderModal();
  if (!modal_type) {
    state.pop();
    return state;
  }

  if (modal_type === "all") {
    state = [];
    return state;
  }

  const index = state.findIndex(item => item.type === modal_type);
  if (index > -1) {
    state.splice(index, 1);
  }
  return state;
};

const initialState: ModalType[] = [];

const modals = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openModal: openModalReducer,
    closeModal: closeModalReducer
  }
});

export const modalsActions = modals.actions;
export const { openModal, closeModal } = modals.actions;
export default modals.reducer;
