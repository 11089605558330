import { getThemeFromWhitelabel } from "@app/helpers/theme";

export async function setupWhitelabelTheme() {
  if (
    !window ||
    !window.BB ||
    !window.BB.whitelabel ||
    window.BB.whitelabel_theme !== undefined
  ) {
    return;
  }
  const theme = await getThemeFromWhitelabel(window.BB.whitelabel);
  if (theme) {
    window.BB.whitelabel_theme = theme;
  }
}
