export class DropdownChip {
  private timeout: null | NodeJS.Timeout = null;
  private content: Element;
  private chip: Element;
  public static dropdown_base_id = "dropdown-chip-";

  public constructor(private container: ParentNode) {
    this.content = container.querySelector(".js-header-dropdown") as Element;
    this.chip = this.content.previousElementSibling?.querySelector(
      "[data-header-chip]"
    ) as Element;

    if (!this.chip) {
      return;
    }

    this.attachContainerListeners();
    this.attachContentListeners();
  }

  public static getId(id: string): string {
    return `${DropdownChip.dropdown_base_id}${id}`;
  }

  private attachContainerListeners(): void {
    this.container.addEventListener("mouseenter", this.open.bind(this));
    this.container.addEventListener("mouseleave", () => {
      this.timeout = setTimeout(this.close.bind(this), 100);
    });
    this.container.addEventListener("mousemove", this.open.bind(this));
  }

  private attachContentListeners(): void {
    this.content.addEventListener("mouseenter", () => {
      clearTimeout(this.timeout as NodeJS.Timeout);
      this.open();
    });

    this.content.addEventListener("mouseleave", () => {
      this.close();
    });
  }

  private close(): void {
    this.content.classList.remove("open");
    this.content.addEventListener(
      "transitionend",
      () => {
        this.content.setAttribute("hidden", "");
      },
      { once: true }
    );
  }

  private open(): void {
    this.content.removeAttribute("hidden");
    setTimeout(() => this.content.classList.add("open"), 50);
  }
}
