import {
  createAction,
  createSlice,
  CaseReducer,
  PayloadAction
} from "@reduxjs/toolkit";

import { NapiUser } from "@app/types/napi-types";
import { SigningMethod } from "@app/types/tracking/account";

type User = NapiUser | null;

type LogUserInPayload = {
  user: NapiUser;
  redirect_url?: string;
  sign_in_method?: SigningMethod;
};

const logUserInReducer: CaseReducer<User, PayloadAction<LogUserInPayload>> = (
  _state,
  action
) => action.payload.user;

const updateUserReducer: CaseReducer<User, PayloadAction<NapiUser>> = (
  state,
  action
) => ({ ...state, ...action.payload });

const user = createSlice({
  name: "user",
  initialState: null as User,
  reducers: {
    logUserIn: logUserInReducer,
    updateUser: updateUserReducer
  }
});

export const updateUserError = createAction<any>("updateUserError");
export const userActions = user.actions;
export const { logUserIn, updateUser } = user.actions;
export default user.reducer;
