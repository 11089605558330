export abstract class BrowserStorage {
  public abstract setItem(key: string, value: unknown): void;
  public abstract getItem<T>(key: string): T | null;

  protected serialize(value: unknown): string {
    return JSON.stringify(value);
  }

  protected deserialize<T>(value: string): T {
    return JSON.parse(value) as T;
  }

  protected canBeStored(value: unknown): boolean {
    return value !== null && value !== undefined;
  }
}
