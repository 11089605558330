import { BrowserStorage } from "@app/lib/bowser-storage/browser-storage";
import { showedModal } from "@app/tracking/modals-tracking";
import type { Tracker } from "@app/tracking/tracker";
import { ExperimentsVariations } from "@app/types/experiments";

export const ITEM_CLOSED_COUNT = "app_promotion_sticky_banner_closed_count";
export const ITEM_LAST_DISPLAY_DATE =
  "app_promotion_sticky_banner_last_display_date";

export class StickyBannerVanillaJs {
  private readonly container: Element | null;

  public constructor(
    private readonly document: Document,
    private readonly experiments: Partial<ExperimentsVariations>,
    private readonly tracker: Tracker,
    private readonly browserStorage: BrowserStorage
  ) {
    this.container = this.document.querySelector(
      "#app-promotion-banner-container"
    );

    this.init();
  }

  private init(): void {
    if (this.container) {
      this.initCloseBtnHandler();
      this.showBanner();
    }
  }

  private hasAcceptedFunctionalCookies(): boolean {
    return /C0003/.test(window.OnetrustActiveGroups || "");
  }

  private isConsentRequired(): boolean {
    return (
      (!!this.experiments["banner_cookie_consent_ca"] &&
        this.experiments["banner_cookie_consent_ca"] === "onetrust") ||
      (!!this.experiments["banner_cookie_consent_eu"] &&
        this.experiments["banner_cookie_consent_eu"] === "onetrust")
    );
  }

  private initCloseBtnHandler(): void {
    const close_btn = this.document.querySelector(
      "#app-promotion-banner-close-btn"
    );
    if (close_btn) {
      close_btn.addEventListener("click", () => {
        this.hideBanner();
        this.saveBannerClosedState();
      });
    }
  }

  private canSaveStateInCookie(): boolean {
    return this.isConsentRequired()
      ? this.hasAcceptedFunctionalCookies()
      : true;
  }

  private saveBannerClosedState(): void {
    if (this.canSaveStateInCookie()) {
      this.saveBannerClosedCount();
      this.saveBannerLastDisplayDate();
    }
  }

  private trackBannerDisplayed(): void {
    this.tracker.asyncTrack(showedModal("app_promotion_sticky_banner"));
  }

  private showBanner(): void {
    if (this.shouldShowBanner()) {
      this.container?.classList.remove("hidden");
      this.trackBannerDisplayed();
    }
  }

  private hideBanner(): void {
    this.container?.classList.add("hidden");
  }

  private getBannerClosedCount(): number {
    const str_count = this.browserStorage.getItem<number>(ITEM_CLOSED_COUNT);
    return str_count ? str_count : 0;
  }

  private saveBannerClosedCount(): void {
    const count = this.getBannerClosedCount();
    this.browserStorage.setItem(ITEM_CLOSED_COUNT, count + 1);
  }

  private getBannerLastDisplayDate(): Date | null {
    const str_date = this.browserStorage.getItem<string>(
      ITEM_LAST_DISPLAY_DATE
    );
    return str_date ? new Date(str_date) : null;
  }

  private saveBannerLastDisplayDate(): void {
    const serialized_today = this.serializedDate(new Date());
    this.browserStorage.setItem(ITEM_LAST_DISPLAY_DATE, serialized_today);
  }

  private isToday(date: Date): boolean {
    const today_str = this.serializedDate(new Date());
    const date_str = this.serializedDate(date);

    return today_str === date_str;
  }

  /**
   * Serialize a locale date as a YYYY-MMM-DDT00:00:00 string
   *
   * @param date
   */
  private serializedDate(date: Date): string {
    // We can't use date.toISOString() because it converts the date in UTC.
    // Instead we use toLocaleString with the Swedish formatting since it is really close to the ISO one, just need to swap the space between the date and the time with a `T`.
    // This way we have a ISO like locale date.
    return date.toLocaleString("sv").split(" ")[0] + "T00:00:00";
  }

  /**
   * Rules:
   * - Display the banner only 2 times to the user
   * - Do not show the banner twice the same day
   */
  private shouldShowBanner(): boolean {
    if (this.getBannerClosedCount() >= 2) {
      return false;
    }

    const last_display = this.getBannerLastDisplayDate();
    if (last_display && this.isToday(last_display)) {
      return false;
    }

    return true;
  }
}
