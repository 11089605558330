import { ClickMenu } from "@app/types/tracking/authentication";
import { AmplitudeEvent } from "@app/types/tracking/common";

import { TrackingAction } from "./constants/tracking-action";

export const clickMenu = (
  item: "signin" | "account",
  menu_location: "footer" | "header"
): AmplitudeEvent<ClickMenu> => ({
  action: TrackingAction.ClickMenu,
  details: {
    category: "account",
    menu_location,
    menu_item: item
  }
});
