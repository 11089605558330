import { BrowserStorage } from "./browser-storage";

export class LocalStorage extends BrowserStorage {
  private constructor(private readonly window: Window) {
    super();
  }

  public setItem(key: string, value: unknown): void {
    if (!super.canBeStored(value)) {
      return;
    }

    try {
      this.window.localStorage.setItem(key, super.serialize(value));
    } catch (error) {
      return;
    }
  }

  public getItem<T>(key: string): T | null {
    try {
      const value = this.window.localStorage.getItem(key);
      if (value) {
        return super.deserialize<T>(value);
      }

      return null;
    } catch (error) {
      return null;
    }
  }

  public clear(): void {
    try {
      this.window.localStorage.clear();
    } catch (error) {
      return;
    }
  }

  public static fromWindow(window: Window): LocalStorage {
    return new LocalStorage(window);
  }
}
