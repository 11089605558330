import { combineReducers } from "@reduxjs/toolkit";

import drawers from "./state/drawers";
import modals from "./state/modals";
import user from "./state/user";

export const reducers = {
  drawers,
  modals,
  user
};
const rootReducer = combineReducers(reducers);

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
