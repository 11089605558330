export interface HTMLDialogElementPolyfill extends HTMLElement {
  /** Optional backdrop element for modal dialogs. */
  __backdrop?: HTMLElement;
  /** The last focused element before the dialog was opened. */
  __last_active_element?: Element | null;
  /** Closes the dialog, optionally passing a return value. */
  close(return_value?: string): void;
  /** Indicates whether the dialog is open. */
  open: boolean;
  /** Returns value when the dialog is closed. */
  return_value?: string;
  /** Shows the non-modal dialog. */
  show(): void;
  /** Shows the modal dialog. */
  showModal(): void;
}

// Check if the browser supports `HTMLDialogElement`
if (typeof HTMLDialogElement !== "function") {
  let active_dialog: HTMLDialogElementPolyfill | null = null;
  let max_z_index = 1000;

  // Define the event listener for form submissions within a dialog
  function form_submit_listener(event: SubmitEvent): void {
    const target_form = event.target as HTMLFormElement;

    if (target_form.getAttribute("method") !== "dialog") {
      return;
    }

    event.preventDefault();

    const submitter = event.submitter as HTMLButtonElement | HTMLInputElement;
    const submitter_value = submitter?.value;

    active_dialog?.close(submitter_value);
  }

  // Define the event listener to trap focus inside the dialog
  function prevent_blur_listener(event: FocusEvent): void {
    if (
      !active_dialog ||
      !event.relatedTarget ||
      active_dialog.contains(event.relatedTarget as Node)
    ) {
      return;
    }

    // If the focus moves outside the dialog, return focus back to the element that triggered the event
    (event.target as HTMLElement).focus();
  }

  // Define the event listener for handling the `Escape` key press to close the modal
  function esc_listener(event: KeyboardEvent): void {
    if (event.key === "Escape" && active_dialog) {
      const cancel_event = new Event("cancel", {
        bubbles: true,
        cancelable: true
      });
      active_dialog.dispatchEvent(cancel_event);

      if (!cancel_event.defaultPrevented) {
        active_dialog.close();
      }
    }
  }

  // Assign `HTMLUnknownElement` to `HTMLDialogElement` in the window object for unsupported browsers
  (window as any).HTMLDialogElement = HTMLUnknownElement;

  const proto =
    HTMLUnknownElement.prototype as unknown as HTMLDialogElementPolyfill;

  window.addEventListener("keydown", esc_listener, true);
  document.addEventListener("submit", form_submit_listener, true);
  document.addEventListener("focusin", prevent_blur_listener, true);

  // Define the showModal method for modal dialogs
  proto.showModal = function (this: HTMLDialogElementPolyfill): void {
    // Store the element that was focused before opening the dialog
    this.__last_active_element = document.activeElement;

    // Set the dialog to be open and add modal-related attributes
    this.setAttribute("open", "");
    this.setAttribute("aria-modal", "true");
    this.classList.add("dialog-polyfill-as-modal");

    // Focus the first focusable element within the dialog (if any)
    const focusable_selector =
      "[autofocus], a[href], button, input, textarea, select, details, [contenteditable], [tabindex]";
    const focusable_element =
      this.querySelector<HTMLElement>(focusable_selector);
    focusable_element?.focus();

    // Create and manage the backdrop for the modal dialog
    if (!this.__backdrop) {
      this.__backdrop = document.createElement("div");
      this.__backdrop.classList.add("dialog-polyfill-backdrop");
    }

    // Set the z-index for the backdrop and dialog, ensuring proper stacking
    this.__backdrop.style.zIndex = `${++max_z_index}`;
    this.after(this.__backdrop);
    this.style.zIndex = `${++max_z_index}`;

    // Set this dialog as the currently active one
    active_dialog = this;
  };

  // Define the show method for non-modal dialogs
  proto.show = function (this: HTMLDialogElementPolyfill): void {
    this.setAttribute("open", "");
    this.setAttribute("role", "dialog");
  };

  // Define the close method to close the dialog and clean up
  proto.close = function (
    this: HTMLDialogElementPolyfill,
    return_value?: string
  ): void {
    this.classList.remove("dialog-polyfill-as-modal");
    this.removeAttribute("open");
    this.setAttribute("aria-modal", "false");
    this.__backdrop?.remove();

    // If a return value is provided, set it on the dialog
    if (return_value !== undefined) {
      this.return_value = return_value;
    }

    // Clear the active dialog and restore focus to the last active element
    active_dialog = null;
    if (this.__last_active_element instanceof HTMLElement) {
      this.__last_active_element.focus();
    }

    // Dispatch a `close` event to notify that the dialog has been closed
    this.dispatchEvent(new Event("close", { bubbles: false }));
  };

  // Define the open property to control the open state of the dialog
  Object.defineProperty(proto, "open", {
    get(this: HTMLDialogElementPolyfill): boolean {
      return this.hasAttribute("open");
    },
    set(this: HTMLDialogElementPolyfill, value: boolean): void {
      // Call the appropriate method (show or close) based on the value
      value ? this.show() : this.close();
    }
  });

  // Insert the polyfill's styles into the document head if it's not already added
  if (!document.getElementById("dialog-polyfill-styles")) {
    const css = `
dialog {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  margin: auto;
  border: initial;
}
dialog:not([open]) {
  display: none;
}
.dialog-polyfill-as-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}
dialog + .dialog-polyfill-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.125);
}
`
      // Minimize whitespace and remove any leading or trailing whitespace
      .replace(/\s+/g, " ")
      .trim();

    const style = document.createElement("style");
    style.id = "dialog-polyfill-styles";
    style.textContent = css;
    document.head.appendChild(style);
  }
}
