import { createContext } from "react";

import { PageEntity } from "@app/lib/page-entity";
import Translator, { LiteTranslatorService } from "@app/services/translator";
import { ValidLiteTranslationKey } from "@app/services/translator-lite.keys";
import LiteUrlBuilderService from "@app/services/url-builders/lite-url-builder";
import UrlBuilderService from "@app/services/url-builders/url-builder";
import type { Tracker } from "@app/tracking/tracker";
import { DeviceInfo } from "@app/types/device";
import { Features } from "@app/types/experiments";

import { SupportedLocale } from "@config/locales";

export interface LiteAppContext {
  entity: PageEntity | null;
  locale: SupportedLocale;
  liteTranslator: LiteTranslatorService;
  features: Partial<Features>;
  device: DeviceInfo | null;
  tracker: Tracker | null;
  urlBuilder: LiteUrlBuilderService | UrlBuilderService;
}

export const LiteAppContext = createContext<LiteAppContext>({
  entity: null,
  locale: "en",
  features: {},
  liteTranslator: new Translator<ValidLiteTranslationKey, "lite">(),
  device: null,
  tracker: null,
  urlBuilder: new LiteUrlBuilderService({
    locale: "en",
    templates: {
      image: "https://busbud.imgix.net/"
    }
  })
});

export const LiteAppContextProvider = LiteAppContext.Provider;
