import React from "react";

// eslint-disable-next-line no-restricted-imports
import clsx from "clsx";
import { createMakeAndWithStyles } from "tss-react";

import {
  createCache,
  CacheProvider,
  EmotionCache
} from "@busbud/design-system-components/dist/styles/emotion";
import { useTheme } from "@busbud/design-system-components/dist/styles/useTheme";

export { tss } from "@busbud/design-system-components/dist/styles/tss";

export const staticClsx = clsx;

export const TSS_PREFIX = "t";

let tssCache: EmotionCache | undefined = undefined;

export const createTssCache = (): EmotionCache => {
  const cache = createCache({
    key: TSS_PREFIX,
    // Disable the vendor prefixer server-side, it does nothing.
    stylisPlugins: typeof window === "undefined" ? [] : undefined
  });
  cache.compat = true;
  return cache;
};

const createClientTssCache = (): EmotionCache => {
  if (typeof window === "undefined") {
    throw new Error("createClientTssCache should only be called client-side");
  }

  return tssCache ?? (tssCache = createTssCache());
};

export const TSSProvider: React.FC<{
  cache?: EmotionCache;
  children: React.ReactNode;
}> = ({ cache, children }) => {
  return (
    <CacheProvider value={cache ?? createClientTssCache()}>
      {children}
    </CacheProvider>
  );
};

export const { makeStyles: makeTssStyles } = createMakeAndWithStyles({
  useTheme
});
