export class CountdownTimer {
  private timer: HTMLElement | null;
  private countdown: number = 0;
  private interval: NodeJS.Timeout | null = null;
  private time_up_message: string = "";

  public constructor() {
    this.timer = document.getElementById("js-countdown-timer");
    if (!this.timer) {
      return;
    }

    this.countdown = Number(this.timer.getAttribute("data-start-time"));
    this.time_up_message =
      this.timer.getAttribute("data-time-up-message") || "";
  }

  public start() {
    if (!this.timer) {
      return;
    }

    this.timer.innerText = `${this.countdown}`;
    this.interval = setInterval(() => {
      if (!this.timer) {
        return;
      }

      this.countdown--;
      this.timer.innerText = `${this.countdown}`;
      if (this.countdown === 0 && this.interval) {
        clearInterval(this.interval);
        this.timer.innerText = this.time_up_message;
      }
    }, 1000);
  }

  public stop() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
